<template>
  <div class="user-coins-wrapper">
    <img class="prken-coin-img" src="../assets/prken_coin_small.png" alt="prken coin" />
    <p>{{ coins }}</p>
  </div>
  <div class="content-container">
    <h3>Spotlight Avatars</h3>
    <div class="avatar-container spotlight-container">
      <div class="avatar-list">
        <div v-for="(avatar, key) in spotlightAvatars" :key="key" :class="['avatar-item']"
          @click="selectAvatar(avatar.id)">
          <div class="image-wrapper">
            <img :src="avatar.url" :alt="avatar.name" />
          </div>
          <div class="text-wrapper">
            <div class="price-wrapper">
              <img class="prken-coin-img" src="../assets/prken_coin_small.png" alt="prken coin" />
              <p>{{ avatar.price }}</p>
            </div>
            <p class="avatar-display-name">{{ avatar.name }}</p>
          </div>
        </div>
      </div>
    </div>
    <h3>All Prken Avatars</h3>
    <div class="avatar-container">
      <div class="avatar-list">
        <div v-for="(avatar, key) in avatars" :key="key" :class="['avatar-item']" @click="selectAvatar(key)">
          <div class="image-wrapper">
            <img :src="avatar.url" :alt="avatar.name" />
          </div>
          <div class="text-wrapper">
            <div class="price-wrapper">
              <img class="prken-coin-img" src="../assets/prken_coin_small.png" alt="prken coin" />
              <p>{{ avatar.price }}</p>
            </div>
            <p class="avatar-display-name">{{ avatar.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { ref, onMounted, computed } from 'vue';
import { getAvatars } from '../avatars/loader';
import { getUserCoins } from '../user/api';

const avatars = ref([]);
const spotlightAvatars = computed(() => selectSpotlightAvatars());
const coins = ref(0);

const router = useRouter();

const selectAvatar = (key) => {
  console.log('Selecting avatar', key);
  router.push({ name: 'BuyAvatar', params: { key } });
};

const selectSpotlightAvatars = () => {
  // Selecting two random avatars from the list that have a price.
  console.log('type values', typeof avatars.value);
  if (Array.isArray(avatars.value)) {
    console.log('Array');
  } else {
    var test = [];
    Object.values(avatars.value).forEach((avatar) => {
      if (avatar.price > 0) {
        test.push(avatar);
      }
    });
    return test.sort(() => 0.5 - Math.random()).slice(0, 2);
  }
  return [];
};

onMounted(async () => {
  avatars.value = await getAvatars();
  console.log(avatars);
  coins.value = await getUserCoins();
  console.log(coins.value);
  spotlightAvatars.value = selectSpotlightAvatars(avatars.value);
});
</script>

<style scoped>
.user-coins-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0px 0px;
  position: absolute;
  top: 60px;
  left: calc(20px + (100% - 320px) * 0.51);
}

.user-coins-wrapper p {
  margin-left: 5px;
  font-weight: 500;
  margin-right: 10px;
}

.avatar-container {
  width: 270px;
  /* Set your desired fixed width */
  overflow-x: auto;
  /* Enable horizontal scrolling */
  overflow-y: hidden;
  /* Hide vertical scrollbar, if any */
  margin-top: 10px;
}

.spotlight-container {
  margin-bottom: 30px;
}

.avatar-list {
  display: flex;
  /* Use Flexbox for horizontal layout */
  flex-wrap: nowrap;
  /* Prevent items from wrapping to the next line */
}

.avatar-item {
  flex: 0 0 auto;
  /* Prevent flex items from shrinking */
  margin-right: 10px;
  /* Optional spacing between icons */
  border-radius: 5px;
  width: 100px;
  cursor: pointer;
  background-color: #FFF1E8;
  color: #424242;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.image-wrapper img {
  width: 75px;
  height: 75px;
}

.text-wrapper {
  text-align: center;
}

.price-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.prken-coin-img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.avatar-display-name {
  font-size: 12px;
}
</style>