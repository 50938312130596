<template>
  <div id="bottom-control-bar-container">
    <div class="bottom-button button-play" @click="clickButton('play')">
      <img src="@/assets/play-icon.png" alt="Play" />
      <p>Play</p>
    </div>
    <div class="bottom-button button-replay" @click="clickButton('replay')">
      <img src="@/assets/replay-icon.png" alt="Replay" />
      <p>Replay</p>
    </div>
    <div class="bottom-button button-layers" @click="clickButton('layers')">
      <img src="@/assets/layers-icon.png" alt="Layers" />
      <p>Layers</p>
    </div>
    <div class="bottom-button button-layers" @click="clickButton('bounties')">
      <img src="@/assets/layers-icon.png" alt="Shop" />
      <p>Bounties</p>
    </div>
  </div>
</template>

<script setup>
import { defineEmits } from 'vue';
const emit = defineEmits(['clickButton']);

const clickButton = (buttonName) => {
  emit('clickButton', buttonName);
};
</script>

<style scoped>
#bottom-control-bar-container {
  width: 100%;
  height: 10vh;
  height: 10dvh;
  min-height: 48px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  bottom: 0px;
}

.bottom-button {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.bottom-button p {
  font-size: 12px;
  margin: 0;
  margin-top: -8px;
}

.button-play img,
.button-replay img,
.button-layers img {
  width: 35px;
  height: 35px;
}
</style>