<template>
  <div class="user-coins-wrapper">
    <img class="prken-coin-img" src="../assets/prken_coin_small.png" alt="prken coin" />
    <p>{{ coins }}</p>
  </div>
  <div class="content-container">
    <div class="avatar-container">
      <img class="selected-avatar-icon" :src="avatar.url" alt="Selected Avatar" />
      <p>{{ avatar.name }}</p>
    </div>
    <div v-if="avatarIsOwned">
      <p class="avatar-is-owned-text" >You own this avatar.</p>
    </div>
    <div v-else>
      <a v-if="enoughMoneyToBuy" class="button" @click="buyAvatar">Buy Avatar for {{ avatar.price }} coins</a>
      <p v-else class="avatar-is-owned-text">You don't have enough coins to buy this avatar.</p>
    </div>
    <router-link class="button" to="/shop">Go Back to Shop</router-link>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
import { getAvatar } from '../avatars/loader';
import { getUserCoins, getUserAvatars } from '../user/api';
import axios from 'axios';

const avatar = ref({
  name: '',
  url: ''
});
const coins = ref(0);
const router = useRouter();
const avatarKey = ref(router.currentRoute.value.params.key);
const userAvatars = ref([]);
const avatarIsOwned = ref(false);
const enoughMoneyToBuy = ref(false);
console.log(avatarKey);

const buyAvatar = async () => {
  console.log("Buying avatar", avatarKey.value);
  const avatarId = avatarKey.value;
  const response = await axios.post(`/api/shop-buy-avatar/${avatarId}`, { key: avatarKey.value });
  console.log(response);
  if (response.data.success) {
    // Add CSS class to animate the image
    const selectedAvatarIcon = document.querySelector('.selected-avatar-icon');
    selectedAvatarIcon.classList.add('bounce-in');
    coins.value -= avatar.value.price;
    avatarIsOwned.value = true;
  }
};

onMounted(async () => {
  console.log("getting avatar");
  avatar.value = await getAvatar(avatarKey.value);
  console.log(avatar);
  coins.value = await getUserCoins();
  console.log(coins.value);
  userAvatars.value = await getUserAvatars();
  console.log(userAvatars.value);
  userAvatars.value.forEach((userAvatar) => {
    if (userAvatar.name === avatarKey.value) {
      avatarIsOwned.value = true;
    }
  });
  enoughMoneyToBuy.value = coins.value >= avatar.value.price;
});

</script>

<style scoped>
.user-coins-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0px 0px;
  position: absolute;
  top: 60px;
  left: calc(20px + (100% - 320px) * 0.51);
}

.user-coins-wrapper p {
  margin-left: 5px;
  font-weight: 500;
  margin-right: 10px;
}

.prken-coin-img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.avatar-container {
  text-align: center;
  margin-bottom: 50px;
}

.selected-avatar-icon {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 10px;
  display: block;
}

.avatar-is-owned-text {
  text-align: center;
  margin-bottom: 20px;
}

.button {
  display: block;
  width: 270px;
  padding: 5px;
  margin: 0px auto;
  margin-bottom: 20px;
  border-radius: 5px;
  background: #ffffff;
  outline: 1px solid #ccc;
  text-align: center;
  text-decoration: none;
  color: black;
  font-weight: 500;
  cursor: pointer;
}

.bounce-in {
  -webkit-animation-name: bounce-in;
  animation-name: bounce-in;
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes bounce-in {

  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 1;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(.97, .97, .97);
    transform: scale3d(.97, .97, .97);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounce-in {

  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 1;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(.97, .97, .97);
    transform: scale3d(.97, .97, .97);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
</style>