<template>
  <div id="content-container" :style="{ height: props.containerHeight }">
    <div id="content-container-inner">
      <PlayComponent 
        v-if="props.mode === 'play'" 
        @emitObservationSubmitted="onObservationSubmitted" 
      />
      <CarouselComponent 
        v-if="props.mode === 'replay'" 
        :previousSliderIdx="props.carouselSliderValue" 
        @emitSliderChange="onSliderEventCallback" 
      />
      <LayersControlComponent 
        v-if="props.mode === 'layers'" 
        :avatarLayerVisible="props.avatarLayerVisibleRef" 
        :freeParkingLayerVisible="props.freeParkingLayerVisibleRef" 
        :takenParkingLayerVisible="props.takenParkingLayerVisibleRef" 
        @emitLayerChange="onLayerSelectEventCallback" 
      />
      <BountiesComponent 
        v-if="props.mode === 'bounties'" 
        :h3Index="props.selectedHexagon"
        @emitBountySubmitted="onBountySubmitted"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import PlayComponent from '@/prken_land/PlayComponent.vue';
import CarouselComponent from '@/prken_land/CarouselComponent.vue';
import LayersControlComponent from '@/prken_land/LayersControlComponent.vue';
import BountiesComponent from '@/prken_land/BountiesComponent.vue';

const props = defineProps({
  mode: String,
  containerHeight: String,
  carouselSliderValue: Number,
  selectedHexagon: String,
  avatarLayerVisibleRef: Boolean,
  freeParkingLayerVisibleRef: Boolean,
  takenParkingLayerVisibleRef: Boolean,
});

const emit = defineEmits(['emitObservationSubmitted', 'emitSliderChange', 'emitLayerChange', 'emitBountySubmitted']);

const onObservationSubmitted = (observation) => {
  emit('emitObservationSubmitted', observation);
};

const onSliderEventCallback = (observations, requestedDate, sliderValue) => {
  emit('emitSliderChange', observations, requestedDate, sliderValue);
};

const onLayerSelectEventCallback = (avatarLayerVisible, freeParkingLayerVisible, takenParkingLayerVisible) => {
  emit('emitLayerChange', avatarLayerVisible, freeParkingLayerVisible, takenParkingLayerVisible);
};

const onBountySubmitted = () => {
  emit('emitBountySubmitted');
};
</script>

<style scoped>
#content-container {
  display: block;
  position: absolute;
  z-index: 2;
  bottom: 10vh;
  width: 100%;
  height: 0px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: #FEF8F4;
}

#content-container-inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
</style>