import maplibregl from 'maplibre-gl';

import parkingSignImage from '@/assets/parking-sign-small.png';
import parkingSignImagePay from '@/assets/parking-sign-small-pay.png';
import bountyImage from '@/assets/prken_coin35x35.png';

import { queryObservationsOnDayN, queryObservationsInBoundsOnWeekday } from '@/observations/api';
import { heatmapTakenStyle, heatmapFreeStyle, avatarLayerStyle } from '@/prken_land/mapLibreConfig';
import { getAvatars } from '@/avatars/loader';
import { addTitleToObservation } from '@/observations/visualize';
import { queryParkingSlots } from '@/parking_slots/api';
import { initializeHexagonDioramaLayer } from '@/prken_land/hexagonDiorama';
import { getBounties } from '@/bounties/api';

const emptyPointFeatureCollection = {
  type: 'geojson',
  data: {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: []
    }
  }
};

/**
 * Creates and initializes a map instance using MapLibre GL.
 *
 * @param {Object} mapContainerRef - A reference to the DOM element that will contain the map.
 * @param {Object} mapContainerRef.value - The actual DOM element.
 * @returns {Object} The initialized map instance.
 */
export const createMap = (mapContainerId) => {
  console.log('Creating map...');
  console.log('Map container:', mapContainerId);
  const mapInstance = new maplibregl.Map({
    container: mapContainerId, // DOM element reference
    style: 'https://tiles.stadiamaps.com/styles/alidade_smooth.json', // Example style
    center: [8.5417, 47.3769], // ZH coordinates
    zoom: 10 // starting zoom
  });

  // Add location control
  const geolocateControl = new maplibregl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true
    },
    trackUserLocation: true
  });

  mapInstance.addControl(geolocateControl, 'bottom-right');

  // Add basic map navigation control.
  mapInstance.addControl(new maplibregl.NavigationControl(), 'bottom-right');

  console.log('Map created');

  mapInstance.on('load', () => {
    console.log('Map loaded');
    // Enable map interactions after the map has loaded.
    geolocateControl.trigger(); // Trigger geolocation to get the user's location.
  });

  return mapInstance;
};


export const initializePrkenMap = async (mapInstance) => {
  console.log('Initializing map...');
  // Initialize hexagon layer for the map.
  await initializeHexagonDioramaLayer(mapInstance);

  // Load avatar images from backend and add them as available images to the map.
  await loadAvatarImages(mapInstance);

  // Load observations of today from the backend and add them to the map.
  await loadObservations(mapInstance);

  // Prepare and add parking slot layers to the map. 
  await loadParkingSlots(mapInstance);

  // Initialize bounty layer.
  await loadBounties(mapInstance);
};

const loadBounties = async (mapInstance) => {
  const image = await mapInstance.loadImage(bountyImage);
  mapInstance.addImage('bounty-sign', image.data);

  // User click bounty layer.
  mapInstance.addSource('user-add-bounty', emptyPointFeatureCollection);
  mapInstance.addLayer({
    id: 'user-add-bounty',
    type: 'symbol',
    source: 'user-add-bounty',
    layout: {
      'icon-image': 'bounty-sign',
      'icon-size': 1.0,
    }
  });

  // Available bounties layer.
  mapInstance.addSource('available-bounties', emptyPointFeatureCollection);
  mapInstance.addLayer({
    id: 'available-bounties',
    type: 'symbol',
    source: 'available-bounties',
    layout: {
      'icon-image': 'bounty-sign',
      'icon-size': 1.0,
    }
  });

  // Load bounties from backend and add them to the map.
  const bounties = await getBounties();
  console.log('Loaded bounties:', bounties);
  mapInstance.getSource('available-bounties').setData({
    type: 'FeatureCollection',
    features: bounties
  });
}

export const refreshBounties = async (mapInstance) => {
  const bounties = await getBounties();
  console.log('Loaded bounties:', bounties);
  mapInstance.getSource('available-bounties').setData({
    type: 'FeatureCollection',
    features: bounties
  });
};

const loadAvatarImages = async (mapInstance) => {
  // Load avatar images from backend and add them to the map.
  const avatars = await getAvatars();
  console.log('Loaded avatars:', avatars);
  for (let key in avatars) {
    console.log('Loading image:', avatars[key].small_url);
    const image = await mapInstance.loadImage(avatars[key].small_url);
    console.log('Adding image to map:', key);
    mapInstance.addImage(key, image.data);
  }
};

const loadObservations = async (mapInstance) => {
  // Prediction source and layers. 
  mapInstance.addSource('predictions', emptyPointFeatureCollection);
  mapInstance.addLayer(heatmapTakenStyle('prediction-taken', 'predictions'));
  mapInstance.addLayer(heatmapFreeStyle('prediction-free', 'predictions'));

  // Query observations for the current day and add them to the map.
  const queryToday = new Date();
  queryToday.setHours(0, 0, 0, 0);
  const observations = await queryObservationsOnDayN(queryToday);

  // Add title to each observation.
  observations.forEach((observation) => {
    addTitleToObservation(observation);
  });

  // Add initial observation source to the map. 
  mapInstance.addSource('observations', {
    'type': 'geojson',
    'data': {
      'type': 'FeatureCollection',
      'features': observations
    }
  });

  // Add layers to observations data source.
  mapInstance.addLayer(heatmapTakenStyle('live-taken', 'observations'));
  mapInstance.addLayer(heatmapFreeStyle('live-free', 'observations'));
  mapInstance.addLayer(avatarLayerStyle('observations'));


};

export const loadObservationsInCurrentViewOnWeekday = async (mapInstance, weekday) => {
  // Query observations for the current map view and add them to the map.
  const bounds = mapInstance.getBounds();
  const observations = await queryObservationsInBoundsOnWeekday(bounds, weekday);

  const now = new Date();
  const filteredObservations = observations.filter((observation) => {
    const observationDate = new Date(observation.properties.date);
    // Reduce date to only time and remove date.
    observationDate.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());
    const diff = observationDate - now;
    const hours = Math.floor(diff / 1000 / 60 / 60);
    return hours <= 3;
  });

  console.log(`Received ${observations.length} observations from backend and filtered to ${filteredObservations.length}`);
  mapInstance.getSource('predictions').setData({
    'type': 'FeatureCollection',
    'features': filteredObservations
  });
}

const loadParkingSlots = async (mapInstance) => {
  // Initial set is empty since we dynamically load the parking slots based on the map bounds.
  mapInstance.addSource('parking-slots', {
    'type': 'geojson',
    'data': {
      'type': 'FeatureCollection',
      'features': []
    }
  });

  // Prepare parking sign icon from assets. 
  const imageParkingSign = await mapInstance.loadImage(parkingSignImage);
  const imageParkingSignPay = await mapInstance.loadImage(parkingSignImagePay);
  mapInstance.addImage('parking-sign', imageParkingSign.data);
  mapInstance.addImage('parking-sign-pay', imageParkingSignPay.data);

  // Add layers for parking slots.
  mapInstance.addLayer({
    id: 'parking-slots',
    type: 'symbol',
    source: 'parking-slots',
    filter: ['==', ['get', 'gebuehrenpflichtig'], 'nicht gebührenpflichtig'], // Only free slots
    layout: {
      'icon-image': 'parking-sign',
      'icon-size': 1.0,
    }
  });
  mapInstance.addLayer({
    id: 'parking-slots-pay',
    type: 'symbol',
    source: 'parking-slots',
    filter: ['==', ['get', 'gebuehrenpflichtig'], 'gebührenpflichtig'], // Only paid slots
    layout: {
      'icon-image': 'parking-sign-pay',
      'icon-size': 1.0,
    }
  });
  mapInstance.addLayer({
    id: 'parking-slots-openstreetmap',
    type: 'symbol',
    source: 'parking-slots',
    filter: ['==', ['get', 'amenity'], 'parking'], // Openstreemap parking spots
    layout: {
      'icon-image': 'parking-sign',
      'icon-size': 1.0,
    }
  });
};

export const loadParkingSlotsInCurrentView = async (mapInstance) => {
  const bounds = mapInstance.getBounds();
  const parkingSlots = await queryParkingSlots(bounds);
  console.log(`Received ${parkingSlots.length} parking slots from backend`);
  console.log(parkingSlots);
  mapInstance.getSource('parking-slots').setData({
    'type': 'FeatureCollection',
    'features': parkingSlots
  });
};

export const clearParkingSlotData = (mapInstance) => {
  mapInstance.getSource('parking-slots').setData({
    'type': 'FeatureCollection',
    'features': []
  });
};

export const addObservationsToMap = (observations, mapInstance) => {
  observations.forEach((observation) => {
    addTitleToObservation(observation);
  });

  mapInstance.getSource('observations').setData({
    'type': 'FeatureCollection',
    'features': observations
  });
};

const addObservationToMap = (observation, mapInstance) => {
  addTitleToObservation(observation);
  const currentData = mapInstance.getSource('observations')._data;
  currentData.features.push(observation);
  mapInstance.getSource('observations').setData(currentData);
};

export const updateObservationsLayerVisibility = (mapInstance, visibility) => {
  mapInstance.setLayoutProperty('observations', 'visibility', visibility.avatars ? 'visible' : 'none');
  mapInstance.setLayoutProperty('heatmap-taken', 'visibility', visibility.taken ? 'visible' : 'none');
  mapInstance.setLayoutProperty('heatmap-free', 'visibility', visibility.free ? 'visible' : 'none');
};

/**
 * Updates the map instance based on the given observation.
 *
 * @param {Object} observation - The observation data to be added to the map.
 * @param {Object} mapInstance - The map instance to be updated.
 */
export const updateMapOnObservation = (observation, mapInstance) => {
  addObservationToMap(observation, mapInstance);
};

/**
 * Enables map interactions such as scroll zoom and drag pan on the given map instance.
 *
 * @param {Object} mapInstance - The map instance on which to enable interactions.
 */
export const enableMapInteractions = (mapInstance) => {
  mapInstance.scrollZoom.enable();
  mapInstance.dragPan.enable();
  // Handling touch screens
  mapInstance.touchZoomRotate.enable();
};

/**
 * Disables scroll zoom and drag pan interactions on the provided map instance.
 *
 * @param {Object} mapInstance - The map instance on which to disable interactions.
 */
export const disableMapInteractions = (mapInstance) => {
  mapInstance.scrollZoom.disable();
  mapInstance.dragPan.disable();
  // Handling touch screens
  mapInstance.touchZoomRotate.disable();
  mapInstance.touchZoomRotate.enableRotation();
};