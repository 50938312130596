<template>
  <div id="map-container" > <!--class="map-container-class"-->
    <div id="map-coin-visualizer">
      <img class="map-prken-coin" src="@/assets/prken_coin_small.png" alt="Coins" />
      <div class="map-coin-text">{{ props.coins }}</div>
    </div>
    <div id="map-date-visualizer">
      <div class="map-date-text">{{ props.formattedDate }}</div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, watch } from 'vue';

const props = defineProps({
  coins: Number,
  formattedDate: String,
  containerHeight: String,
});

watch(() => props.containerHeight, () => {
  console.log('Container height changed');
  console.log(props.containerHeight);
  adjustPositionMapBottomControlElements();
});

const adjustPositionMapBottomControlElements = () => {
  const bottomDateContainer = document.getElementById('map-date-visualizer');
  const bottomControlBarContainer = document.getElementsByClassName('maplibregl-ctrl-bottom-right ')[0];

  console.log('Adjusting position of bottom control elements');
  console.log(bottomDateContainer.style.bottom);
  bottomControlBarContainer.style.bottom = `${props.containerHeight}`;
  bottomDateContainer.style.bottom = `${props.containerHeight}`;
};
</script>

<style scoped>
#map-container {
  position: relative;
  top: 0px;
  z-index: 1;
  height: 90vh;
  height: 90dvh;
  min-height: 430px;
  width: 100%;
  margin: 0 auto;
}

#map-coin-visualizer {
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  background-color: #ffffff;
  border: 2px solid #070a0d1c;
  border-radius: 12px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.map-prken-coin {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.map-coin-text {
  font-weight: 500;
  font-size: 15px;
  margin: 0;
}

#map-date-visualizer {
  position: absolute;
  z-index: 2;
  bottom: 0px;
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
  border: 2px solid #070a0d1c;
}

.map-date-text {
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: 500;
  font-size: 12px;
}
</style>