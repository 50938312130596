import maplibregl from 'maplibre-gl';
import { cellToLatLng } from "h3-js";
import { distance } from '@turf/turf';

import { flyToHexagonDiorama } from '@/prken_land/mapFlyAnimations';
import { loadObservationsInCurrentViewOnWeekday, loadParkingSlotsInCurrentView, clearParkingSlotData } from '@/prken_land/mapUtils';

export const onMapClickCallback = (event, mapInstance, mode) => {
  // Query features at the clicked point.
  const features = mapInstance.queryRenderedFeatures(event.point, {
    layers: ['observations', 'parking-slots', 'parking-slots-pay', 'available-bounties'],
  });
  console.log('Features:', features);

  // Early exit if no features are found.
  if (features.length === 0 && mode !== 'bounties') {
    return;
  }

  // Logic: Either we clicked on an existing layer from the selection or into empty space.
  let clickedLayer = '';
  if (features.length > 0) {
    clickedLayer = features[0].layer.id;
  }
  console.log(`Clicked on layer: ${clickedLayer}`);

  // Return dict with necessary information for the clicked feature.
  let clickedFeature = {};
  
  // Handle if we clicked on a specific layer, default handles empty click if we are in bounties mode.
  switch (clickedLayer) {
    case 'observations': {
      handleObservationClick(features[0], mapInstance);
      break;
    }
    case 'parking-slots': {
      handleParkingSlotClick('parking-slots', features[0], mapInstance);
      break;
    }
    case 'parking-slots-pay': {
      handleParkingSlotClick('parking-slots-pay', features[0], mapInstance);
      break;
    }
    case 'available-bounties': {
      clickedFeature.h3Index = flyToHexagonDiorama(event, mapInstance);
      clickedFeature.clickedOnAvailableBounty = true;
      console.log('Bounty clicked at:', clickedFeature.h3Index);
      break;
    }
    default: {
      console.log('No feature clicked');
      if (mode === 'bounties') {
        clickedFeature.h3Index = flyToHexagonDiorama(event, mapInstance);
        clickedFeature.clickedOnAvailableBounty = false;
        const hexagonCoordinates = cellToLatLng(clickedFeature.h3Index);

        mapInstance.getSource('user-add-bounty').setData({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [hexagonCoordinates[1], hexagonCoordinates[0]]
              }
            }
          ]
        });
      }
      break;
    }
  }

  console.log('Clicked feature:', clickedFeature);

  return clickedFeature;
};

const handleObservationClick = (observationFeature, mapInstance) => {
  console.log('Observation clicked');

  new maplibregl.Popup()
    .setLngLat(observationFeature.geometry.coordinates)
    .setHTML(
      `${observationFeature.properties.title}`
    )
    .setOffset([0, -15])
    .addTo(mapInstance);
};

const handleParkingSlotClick = (layerId, parkingSlotFeature, mapInstance) => {
  console.log('Parking slot clicked for layer: ' + layerId);

  new maplibregl.Popup()
    .setLngLat(parkingSlotFeature.geometry.coordinates)
    .setHTML(
      `${parkingSlotFeature.properties.art}, ${parkingSlotFeature.properties.gebuehrenpflichtig}. Parkdauer: ${parkingSlotFeature.properties.parkdauer}`
    )
    .setOffset([0, -30])
    .addTo(mapInstance);
};

let previousCenter = null;

export const onMapMoveEndCallback = async (mapInstance, mode) => {
  console.log('Current zoom level: ' + mapInstance.getZoom());

  if (mode === 'bounties') {
    return;
  }

  if (mapInstance.getZoom() < 15) {
    clearParkingSlotData(mapInstance);
    return;
  }

  // Get the center of the map view.
  const currentCenter = mapInstance.getCenter();

  console.log('Current center:', currentCenter);

  if(!previousCenter) {
    previousCenter = currentCenter;
    const weekday = new Date().getDay();
    await loadObservationsInCurrentViewOnWeekday(mapInstance, weekday);
    await loadParkingSlotsInCurrentView(mapInstance);
    return;
  }
  const distanceMoved = distance(
    [previousCenter.lng, previousCenter.lat],
    [currentCenter.lng, currentCenter.lat],
    { units: 'meters' }
  );

  console.log('Distance moved:', distanceMoved);

  previousCenter = currentCenter;

  if (distanceMoved < 10) {
    return;
  }
  const weekday = new Date().getDay();
  await loadObservationsInCurrentViewOnWeekday(mapInstance, weekday);
  await loadParkingSlotsInCurrentView(mapInstance);
}