import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

export async function getBounties() {
  try {
    const response = await axios.get('/api/query-active-bounties');
    return response.data;
  } catch (error) {
    console.error('An error occurred in getBounties:', error);
  }
}