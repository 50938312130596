import { latLngToCell, cellToBoundary, polygonToCells } from "h3-js";

const HEXAGON_FILL_COLOR = 'rgba(255, 0, 0, 0.0)';
const HEXAGON_FILL_OPACITY = 1.0;
const HEXAGON_OUTLINE_COLOR = 'rgba(25, 0, 255, 0)';
const HEXAGON_LINE_COLOR = 'rgb(137, 168, 178)';
const HEXAGON_LINE_OPACITY = 1.0;
const HEXAGON_LINE_WIDTH = 4;
const GRID_FILL_COLOR_SELECTED = 'rgba(254, 248, 244, 0.0)';
const GRID_FILL_COLOR_DEFAULT = 'rgba(254, 248, 244, .8)';
const GRID_FILL_OPACITY = 1.0;
const GRID_OUTLINE_COLOR = 'rgba(0,0,0,0)';
const RESOLUTION = 8;
const BUFFER = 0.05;

const emptyFeatureCollection = {
  type: 'geojson',
  data: {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: []
    }
  }
};

/**
 * Initializes the hexagon diorama layer on the map.
 *
 * @param {Object} map - The map instance.
 */
export const initializeHexagonDioramaLayer = async (map) => {
  try {
    map.addSource('single-hexagon', emptyFeatureCollection);
    map.addSource('hexagon-grid-overlay', emptyFeatureCollection);

    map.addLayer({
      id: 'single-hexagon',
      type: 'fill',
      source: 'single-hexagon',
      layout: {},
      paint: {
        'fill-color': HEXAGON_FILL_COLOR,
        'fill-opacity': HEXAGON_FILL_OPACITY,
        'fill-outline-color': HEXAGON_OUTLINE_COLOR
      }
    });

    map.addLayer({
      id: 'single-hexagon-line',
      type: 'line',
      source: 'single-hexagon',
      layout: {},
      paint: {
        'line-color': HEXAGON_LINE_COLOR,
        'line-opacity': HEXAGON_LINE_OPACITY,
        'line-width': HEXAGON_LINE_WIDTH
      }
    });


    map.addLayer({
      id: 'hexagon-grid-overlay',
      type: 'fill',
      source: 'hexagon-grid-overlay',
      layout: {},
      paint: {
        'fill-color': [
          'case',
          ['get', 'selected'],
          GRID_FILL_COLOR_SELECTED,
          GRID_FILL_COLOR_DEFAULT
        ],
        'fill-opacity': GRID_FILL_OPACITY,
        'fill-outline-color': GRID_OUTLINE_COLOR
      }
    });
  } catch (error) {
    console.error('Error initializing hexagon layer:', error);
  }
};

export const getDioramaResolution = () => RESOLUTION;

/**
 * Draws a hexagon on the map at the specified latitude and longitude.
 *
 * @param {number} lat - The latitude.
 * @param {number} lng - The longitude.
 * @param {Object} map - The map instance.
 * @param {number} resolution - The resolution of the hexagon.
 */
export const drawHexagon = (lat, lng, map, resolution = RESOLUTION) => {
  const hexagon = latLngToCell(lat, lng, resolution);
  const boundary = cellToBoundary(hexagon, true);

  const featureCollection = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [boundary]
        }
      }
    ]
  };

  map.getSource('single-hexagon').setData(featureCollection);
};

/**
 * Draws a hexagon grid on the map.
 *
 * @param {Object} map - The map instance.
 * @param {Array} selectedHexagons - The selected hexagons.
 */
export const drawHexagonGrid = (map, selectedHexagons) => {
  const bounds = map.getBounds();
  const sw = bounds.getSouthWest();
  const ne = bounds.getNorthEast();

  const boundingBox = [
    [sw.lat - BUFFER, sw.lng - BUFFER],
    [ne.lat + BUFFER, sw.lng - BUFFER],
    [ne.lat + BUFFER, ne.lng + BUFFER],
    [sw.lat - BUFFER, ne.lng + BUFFER],
    [sw.lat - BUFFER, sw.lng - BUFFER]
  ];

  const hexagons = polygonToCells(boundingBox, RESOLUTION);

  const featureCollection = {
    type: 'FeatureCollection',
    features: hexagons.map(h3Index => {
      const boundary = cellToBoundary(h3Index, true);
      return {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [boundary]
        },
        properties: {
          h3Index: h3Index,
          selected: selectedHexagons.includes(h3Index),
        },
      };
    })
  };

  map.getSource('hexagon-grid-overlay').setData(featureCollection);
};

/**
 * Draws a hexagon diorama on the map based on the event's latitude and longitude.
 *
 * @param {Object} event - The event object containing the latitude and longitude.
 * @param {Object} event.lngLat - The object containing the latitude and longitude.
 * @param {number} event.lngLat.lat - The latitude of the event.
 * @param {number} event.lngLat.lng - The longitude of the event.
 * @param {Object} mapInstance - The instance of the map where the hexagon will be drawn.
 */
export const drawHexagonDiorama = (event, mapInstance) => {
  const hexagon = latLngToCell(event.lngLat.lat, event.lngLat.lng, RESOLUTION);
  drawHexagonGrid(mapInstance, [hexagon]);
  drawHexagon(event.lngLat.lat, event.lngLat.lng, mapInstance, RESOLUTION);
};

/**
 * Clears the data of a single hexagon on the map.
 *
 * This function sets the data of the 'single-hexagon' source to an empty 
 * FeatureCollection, effectively clearing any existing hexagon data.
 *
 * @param {Object} map - The map object containing the source to be cleared.
 */
export const clearSingleHexagon = (map) => {
  map.getSource('single-hexagon').setData({
    type: 'FeatureCollection',
    features: []
  });
};

/**
 * Clears the hexagon grid from the map.
 *
 * @param {Object} map - The map instance.
 */
export const clearHexagonGrid = (map) => {
  map.getSource('hexagon-grid-overlay').setData({
    type: 'FeatureCollection',
    features: []
  });
};

/**
 * Clears all hexagons from the given map.
 *
 * This function removes both single hexagons and hexagon grids from the map.
 *
 * @param {Object} map - The map object from which hexagons will be cleared.
 */
export const clearHexagonDioramaFromMap = (map) => {
  clearSingleHexagon(map);
  clearHexagonGrid(map);
};