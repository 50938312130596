<template>
  <div class="content-container">
    <div v-if="!selectedAvatar" class="avatar-container">
      <p>Loading...</p>
    </div>
    <div v-else class="avatar-container">
      <img class="selected-avatar-icon" :src="avatar.url" alt="Selected Avatar" />
      <p>Hi, {{ avatar.name }}!</p>
    </div>
    <div class="stats-grid">
      <div class="prken-stat">{{ rank }}</div>
      <div class="prken-stat-text">prken. rank</div>
      <div class="prken-stat">{{ user.prken_coins }}</div>
      <div class="prken-stat-text">prken. coins</div>
    </div>
    <router-link class="button" to="/edit-avatar">Edit Avatar</router-link>
    <a class="button" @click="downloadCSV">Export Observations as CSV</a>
    <a class="button" @click="logout">Logout</a>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { onMounted, ref } from 'vue';
import axios from 'axios';
import { getAvatar } from '../avatars/loader';
import { getUserData } from '../user/api';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

const selectedAvatar = ref(null);
const avatar = ref({
  name: '',
  url: ''
});
const store = useStore();
const rank = ref(0);
const user = ref({
  avatar: '',
  prken_coins: 0,
  userMetadata: {
    email: '',
    picture: ''
  }
});

const logout = () => {
  store.dispatch('logout');
};

const downloadCSV = async () => {
  // GET request to download observations as CSV
  const response = await axios.get('/api/download-observations');

  // Create a Blob from the response data
  const blob = new Blob([response.data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'observations.csv');
  document.body.appendChild(link);
  link.click();
};

const queryLeaderboard = async () => {
  try {
    const response = await axios.get('/api/query-leaderboard');
    console.log(response.data);
    const userIndex = response.data.findIndex((entry) => entry.current_user === true);
    console.log(userIndex);
    rank.value = response.data[userIndex].rank;
  } catch (error) {
    console.error(error);
  }
};

onMounted(async () => {
  user.value = await getUserData();
  selectedAvatar.value = user.value.avatar;
  avatar.value = await getAvatar(selectedAvatar.value);
  console.log(avatar.value);
  console.log(user.value);
  queryLeaderboard();
});
</script>

<script>
export default {
  name: 'SettingsPage',
};
</script>

<style scoped>
.avatar-container {
  text-align: center;
  margin-bottom: 50px;
}

.selected-avatar-icon {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 10px;
  display: block;
}

.stats-grid {
  display: grid;
  grid-template-columns: 100px 200px;
  gap: 10px;
  margin-bottom: 50px;

}

.prken-stat {
  font-size: 30px;
  font-weight: 500;
  color: #ffb685;
  margin-right: 5px;
  text-align: right;
}

.prken-stat-text {
  font-size: 20px;
  margin-top: 10px;
}

.button {
  display: block;
  width: 270px;
  padding: 5px;
  margin: 0px auto;
  margin-bottom: 20px;
  border-radius: 5px;
  background: #ffffff;
  outline: 1px solid #ccc;
  text-align: center;
  text-decoration: none;
  color: black;
  font-weight: 500;
  cursor: pointer;
}
</style>