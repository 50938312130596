import { latLngToCell, cellToLatLng } from "h3-js";

import { drawHexagonDiorama, getDioramaResolution, clearHexagonDioramaFromMap } from './hexagonDiorama';
import { disableMapInteractions, enableMapInteractions } from './mapUtils';

/**
 * Animates the map to fly out to the default location with a zoom level of 12.
 *
 * @param {Object} mapInstance - The map instance to perform the fly out animation on.
 * @param {Function} mapInstance.getCenter - Function to get the current center of the map.
 * @param {Function} mapInstance.flyTo - Function to animate the map to a new location.
 */
export const flyOutToDefaultLocation = (mapInstance) => {
  // Zoom out from current location.
  const center = mapInstance.getCenter();

  const bearingAngle = 0;
  const pitchAngle = 0;
  const animationDuration = 1000;

  mapInstance.flyTo({
    center: [center.lng, center.lat],
    zoom: 12,
    essential: true,
    bearing: bearingAngle,
    pitch: pitchAngle,
    duration: animationDuration,
  });
};

/**
 * Flies to the hexagon diorama on the map at the clicked point.
 *
 * @param {Object} event - The map click event.
 * @param {Object} mapInstance - The map instance.
 */
export const flyToHexagonDiorama = (event, mapInstance) => {
  const lat = event.lngLat.lat;
  const lng = event.lngLat.lng;
  const resolution = getDioramaResolution();
  const hexagon = latLngToCell(lat, lng, resolution);
  const hexCenterCoordinates = cellToLatLng(hexagon);

  const zoom = 14;
  const bearingAngle = 0;
  const pitchAngle = 60;
  const animationDuration = 2000;

  mapInstance.flyTo({
    center: [hexCenterCoordinates[1], hexCenterCoordinates[0]],
    zoom: zoom,
    bearing: bearingAngle,
    pitch: pitchAngle,
    duration: animationDuration,
  });

  disableMapInteractions(mapInstance);
  drawHexagonDiorama(event, mapInstance);

  return hexagon;
};

/**
 * Transitions the map view out from the hexagon diorama to the default location,
 * enables map interactions, and clears hexagons from the map.
 *
 * @param {Object} mapInstance - The instance of the map to manipulate.
 */
export const flyOutFromHexagonDiorama = (mapInstance) => {
  flyOutToDefaultLocation(mapInstance);
  enableMapInteractions(mapInstance);
  clearHexagonDioramaFromMap(mapInstance);
};