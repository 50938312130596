<template>
  <div id="prken-land">
    <MapContainer :coins="coins" :formattedDate="formattedDate" :containerHeight="containerHeight" />
    <ContentContainer :mode="mode" :containerHeight="containerHeight" :carouselSliderValue="carouselSliderValue"
      :selectedHexagon="selectedHexagon" :avatarLayerVisibleRef="avatarLayerVisibleRef"
      :freeParkingLayerVisibleRef="freeParkingLayerVisibleRef"
      :takenParkingLayerVisibleRef="takenParkingLayerVisibleRef" @emitObservationSubmitted="onObservationSubmitted"
      @emitSliderChange="onSliderEventCallback" @emitLayerChange="onLayerSelectEventCallback"
      @emitBountySubmitted="onBountySubmitted" />
    <BottomControlBar @clickButton="onClickBottomControlBarCallback" />
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import axios from 'axios';

import confetti from 'canvas-confetti';

import 'maplibre-gl/dist/maplibre-gl.css';

import { flyOutFromHexagonDiorama } from '@/prken_land/mapFlyAnimations';
import { createMap, initializePrkenMap, addObservationsToMap, updateMapOnObservation, updateObservationsLayerVisibility, refreshBounties } from '@/prken_land/mapUtils';
import { onMapClickCallback, onMapMoveEndCallback } from '@/prken_land/mapEventCallbacks';
import { getUserData, getUserCoins } from '@/user/api';
import { queryObservationsOnDayN } from "@/observations/api";

import BottomControlBar from '@/prken_land/BottomControlBar.vue';
import MapContainer from '@/prken_land/MapContainer.vue';
import ContentContainer from './ContentContainer.vue';

// References for the map container and the map instance
const mapInstanceRef = ref(null); // Map instance reference

const carouselSliderValue = ref(1);

// Mode for the bottom control bar buttons. Possible values: 'play', 'replay', 'layers', 'bounties'
const mode = ref('');
const containerHeight = ref('0px');

const avatarLayerVisibleRef = ref(true);
const freeParkingLayerVisibleRef = ref(true);
const takenParkingLayerVisibleRef = ref(true);

// Selected date for data view.
const selectedDate = ref(new Date());

const coins = ref(0);

const selectedHexagon = ref(null);

const formattedDate = computed(() => {
  return `${selectedDate.value.getDate()}.${selectedDate.value.getMonth() + 1}.${selectedDate.value.getFullYear()}`;
});

onMounted(async () => {
  // Get the user's coins.
  coins.value = await getUserCoins();

  const mapContainerId = 'map-container';
  mapInstanceRef.value = createMap(mapContainerId);  // Create MapLibre GL JS map

  mapInstanceRef.value.on('load', async () => {
    await initializePrkenMap(mapInstanceRef.value);
  });

  // Register callbacks on different events.
  mapInstanceRef.value.on('click', onMapClickCallbackWrapper);
  mapInstanceRef.value.on('moveend', onMapMoveEndCallbackWrapper);

  // Open play page by default.
  onClickBottomControlBarCallback('play');
});

onBeforeUnmount(() => {
  // Clean up the map instance to avoid memory leaks
  if (mapInstanceRef.value) {
    mapInstanceRef.value.off('click', onMapClickCallbackWrapper);
    mapInstanceRef.value.off('moveend', onMapMoveEndCallbackWrapper);
    mapInstanceRef.value.remove();
    mapInstanceRef.value = null;
  }
});

/**
 * Callback function to handle layer visibility changes on the map.
 *
 * @param {boolean} avatarLayerVisible - Indicates whether the avatar layer should be visible.
 * @param {boolean} freeParkingLayerVisible - Indicates whether the free parking layer should be visible.
 * @param {boolean} takenParkingLayerVisible - Indicates whether the taken parking layer should be visible.
 */
const onLayerSelectEventCallback = (avatarLayerVisible, freeParkingLayerVisible, takenParkingLayerVisible) => {
  if (!mapInstanceRef.value) {
    return;
  }

  avatarLayerVisibleRef.value = avatarLayerVisible;
  freeParkingLayerVisibleRef.value = freeParkingLayerVisible;
  takenParkingLayerVisibleRef.value = takenParkingLayerVisible;

  updateObservationsLayerVisibility(mapInstanceRef.value, {
    avatars: avatarLayerVisible,
    free: freeParkingLayerVisible,
    taken: takenParkingLayerVisible,
  });
};

/**
 * Callback function to handle clicks on the bottom control bar buttons.
 * Depending on the button clicked, it performs different actions.
 *
 * @param {string} buttonName - The name of the button that was clicked.
 * 
 * - 'play': Calls handlePlayButtonClick with the content container element.
 * - 'replay': Calls handleReplayButtonClick with the content container element.
 * - 'layers': Calls handleLayersButtonClick with the content container element.
 * - 'bounties': Calls handleBountiesButtonClick with the content container element.
 * - default: Nothing.
 */
const onClickBottomControlBarCallback = (buttonName) => {
  switch (buttonName) {
    case 'play': {
      handleButtonClick('play', '120px')
      break;
    }
    case 'replay': {
      handleButtonClick('replay', '55px');
      break;
    }
    case 'layers': {
      handleButtonClick('layers', '130px');
      break;
    }
    case 'bounties': {
      handleButtonClick('bounties', '150px');
      break;
    }
    default:
      console.log('Default.');
      break;
  }
};

/**
 * Handles the button click event and toggles the visibility of different sections based on the button type.
 * It also adjusts the height of the content container accordingly.
 *
 * @param {string} selectedMode - The type of button that was clicked ('play', 'replay', 'layers', 'bounties').
 * @param {string} height - The height to set for the content container when a section is shown.
 */
const handleButtonClick = (selectedMode, height) => {
  console.log(`${selectedMode} button clicked`);
  if (mode.value === selectedMode) {
    mode.value = '';
  } else {
    mode.value = selectedMode;
  }

  containerHeight.value = mode.value != '' ? height : '0px';

  if (mode.value != 'bounties' && selectedHexagon.value) {
    // Clean up dioarama if it is open.
    flyOutFromHexagonDiorama(mapInstanceRef.value);
    selectedHexagon.value = null;

    // Reset the parking slots and observations layers.
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    queryObservationsOnDayN(today).then((observations) => {
      addObservationsToMap(observations, mapInstanceRef.value);
    });
  }
};

/**
 * Wrapper function for handling the end of a map move event.
 * Ensures that the map instance is available before calling the actual callback.
 * 
 * @async
 * @function onMapMoveEndCallbackWrapper
 * @returns {Promise<void>}
 */
const onMapMoveEndCallbackWrapper = async () => {
  if (!mapInstanceRef.value) {
    return;
  }

  await onMapMoveEndCallback(mapInstanceRef.value, mode.value);
};

/**
 * Wrapper function for handling map click events.
 * Ensures that the map instance is available before calling the actual callback.
 * 
 * @function onMapClickCallbackWrapper
 * @param {Object} event - The event object from the map click.
 */
const onMapClickCallbackWrapper = async (event) => {
  if (!mapInstanceRef.value) {
    return;
  }

  const clickedFeature = onMapClickCallback(event, mapInstanceRef.value, mode.value);

  console.log(clickedFeature);

  if (!clickedFeature) {
    return;
  }

  if (clickedFeature.h3Index) {
    console.log(clickedFeature.h3Index);
    selectedHexagon.value = clickedFeature.h3Index;

    const parkingSlots = await axios.post('/api/query-hexagon-parking-slots', {
      h3Index: selectedHexagon.value,
    });

    mapInstanceRef.value.getSource('parking-slots').setData({
      type: 'FeatureCollection',
      features: parkingSlots.data,
    });

    const weekday = new Date().getDay();
    const accumulatedObservations = await axios.post('/api/observations-in-hexagon-on-weekday', {
      h3Index: selectedHexagon.value,
      weekday: weekday,
    });

    // Filter out observations within the next 3 hours.
    const now = new Date();
    const filteredObservations = accumulatedObservations.data.filter((observation) => {
      const observationDate = new Date(observation.properties.date);
      // Reduce date to only time and remove date.
      observationDate.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());
      const diff = observationDate - now;
      const hours = Math.floor(diff / 1000 / 60 / 60);
      return hours <= 3;
    });

    mapInstanceRef.value.getSource('observations').setData({
      type: 'FeatureCollection',
      features: filteredObservations,
    });

    if (clickedFeature.clickedOnAvailableBounty) {
      handleButtonClick('bounties', '130px');
    }
  }
};

/**
 * Callback function for handling slider events.
 * Ensures that the map instance is available before adding observations to the map.
 * Updates the selected date and carousel slider value.
 * 
 * @function onSliderEventCallback
 * @param {Array} observations - The list of observations to add to the map.
 * @param {string} requestedDate - The date requested by the slider.
 * @param {number} sliderValue - The current value of the slider.
 */
const onSliderEventCallback = (observations, requestedDate, sliderValue) => {
  if (!mapInstanceRef.value) {
    return;
  }

  console.log('Slider event callback');
  console.log(requestedDate);
  console.log(sliderValue);

  addObservationsToMap(observations, mapInstanceRef.value);

  selectedDate.value = requestedDate;
  carouselSliderValue.value = sliderValue;
};

/**
 * Callback function for handling observation submission events.
 * Updates the user's coin count and the map instance with the new observation.
 * 
 * @function onObservationSubmitted
 * @param {Object} observation - The observation object to add to the map.
 */
const onObservationSubmitted = async (observation) => {
  const userData = await getUserData();
  coins.value = userData.prken_coins;
  const avatar = await userData.avatar;
  observation.properties.avatar = avatar;

  // Pop confetti
  confetti({
    particleCount: 100,
    spread: 70,
    origin: { y: 0.8 }
  });

  updateMapOnObservation(observation, mapInstanceRef.value);
};

const onBountySubmitted = async () => {
  console.log("Bounty submitted!");
  confetti({
    particleCount: 100,
    spread: 70,
    origin: { y: 0.8 }
  });

  refreshBounties(mapInstanceRef.value);
};
</script>

<style scoped src="../prken_land/PrkenLand.css"></style>

<style>
.maplibregl-popup-content {
  border-radius: 30px;
  padding: 8px 20px;
}

.maplibregl-popup-close-button {
  display: none;
}
</style>