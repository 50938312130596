<template>
  <div class="content-container">
    <h3>Select your Avatar:</h3>
    <div class="avatar-grid">
      <div v-for="(avatar, key) in avatars" :key="key" :class="['avatar']" @click="selectAvatar(avatar)">
        <img :src="avatarLibrary[avatar.name].url" :alt="avatarLibrary[avatar.name].name" />
        <p class="avatar-name-text">{{ avatarLibrary[avatar.name].name }}</p>
      </div>
      <div class="avatar shop-wrapper">
        <router-link to="/shop">
          <p class="shop-plus-text">+</p>
          <p class="avatar-name-text">Shop</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import axios from 'axios';
import { getAvatars } from '../avatars/loader';
import { getUserData, getUserAvatars } from '../user/api';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

const avatars = ref([]);
const avatarLibrary = ref([]);
const user = ref({
  avatar: '',
  avatars: [],
  userMetadata: {
    email: '',
    picture: ''
  }
});
const router = useRouter();

// Reactive reference to store selected avatar key
const selectedAvatar = ref(null);

// Method to select an avatar
const selectAvatar = (key) => {
  const avatarName = key.name;
  console.log('Selecting avatar', avatarName);
  selectedAvatar.value = avatarName;
  user.value.avatar = avatarName;
  saveUserData();
};

const saveUserData = async () => {
  console.log('Saving user data');
  console.log(user.value);
  const response = await axios.post('/api/update-user-data', user.value);
  console.log(response);
  router.push('/settings');
};

onMounted(async () => {
  avatarLibrary.value = await getAvatars();
  user.value = await getUserData();
  console.log(user.value);
  avatars.value = await getUserAvatars();
  console.log('Avatars: ' + avatars.value);
});
</script>

<script>
export default {
  name: 'EditAvatarPage',
};
</script>

<style scoped>
h3 {
  text-align: left;
  margin-bottom: 30px;
}

.avatar-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 0px;
}

.avatar {
  margin: 0px;
  text-align: center;
  padding: 0px;
  cursor: pointer;
}

.avatar img {
  width: 75px;
  height: 75px;
}

.avatar-name-text {
  margin: 0px;
  font-size: 14px;
}

.shop-wrapper a{
  text-decoration: none;
}

.shop-wrapper a:visited {
  color: black;
}

.shop-plus-text {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 50px;
  font-size: 51px;
}
</style>