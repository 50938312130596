<template>
  <div class="wrapper">
    <div class="chart-wrapper">
      <p class="chart-text">Activity last 5 days.</p>
      <div class="chart-container">
        <canvas id="observation-activity-chart" ref="chartCanvas" width="200" height="100"></canvas>
      </div>
    </div>
    <div class="submit-wrapper">
      <div v-if="selectedBounty.isActive">
        <p class="bounty-info-text">{{  selectedBounty.bountyValue }} coins reward</p>
        <p class="bounty-info-text">{{  selectedBounty.remainingHours }} hours left</p>
      </div>
      <div v-else>
        <div v-if="userCanSetBounty">
          <p class="bounty-info-text">Boost area with a reward?</p>
          <div class="button-wrapper">
            <a class="button button-confirm" @click="submitBounties">Set Reward</a>
          </div>
        </div>
        <div v-else>
          <p class="bounty-info-text">You can set your next reward in {{ remainingHours }} hours.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;
</script>

<script setup>
import { defineProps, ref, onMounted, defineEmits, onBeforeUnmount, watch, shallowRef } from 'vue';

import { getLastBountySubmissionInfo } from '@/user/api';
import { createChart, updateChartDataset, createDataset } from '@/charts/observationsChart';

const props = defineProps({
  h3Index: String,
});

const emit = defineEmits(['emitBountySubmitted']);

const userLastSetBountyTime = ref(null);
const userCanSetBounty = ref(true);
const remainingHours = ref(0);
const selectedBounty = ref({
  isActive: false,
  bountyValue: null,
  remainingHours: null,
});

const submitBounties = async () => {
  console.log(props.h3Index);
  try {
    const response = await axios.post(`/api/submit-user-bounty`, {
      h3Index: props.h3Index,
    });
    // const response = { data: { success: true } };
    if (response.data.success) {
      console.log('emit: Bounty submitted successfully');
      userCanSetBounty.value = false;
      emit('emitBountySubmitted');
      await updateBountyInfo();
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error('Server error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
  }
};

const updateBountyInfo = async () => {
  const response = await getLastBountySubmissionInfo();
  if (response) {
    console.log(response);
    userLastSetBountyTime.value = response.lastSetBountyTime;
    userCanSetBounty.value = response.canSetBounty;
    remainingHours.value = response.remainingHours;
  }
};

const chartInstance = shallowRef(null);
const chartCanvas = ref(null);

// Watch props.h3Index for changes
watch(
  () => props.h3Index,
  async (newValue, oldValue) => {
    console.log('entry :', newValue);
    if (newValue === null) {
      return;
    }
    if (newValue === oldValue) {
      return;
    }
    console.log('h3Index changed:', newValue);
    await queryBountyInfo(newValue);
    await updateChartData(newValue);
  }
);

const queryBountyInfo = async (h3Index) => {
  const response = await axios.post(`/api/query-bounty-info`, {
    h3Index: h3Index,
  });

  if (!response.data) {
    console.log('No bounty info available.');
    selectedBounty.value.isActive = false;
    return;
  }
  console.log('************');
  console.log(response.data);
  selectedBounty.value.isActive = true;
  selectedBounty.value.bountyValue = response.data.bounty_value * 20;
  const submissionDate = new Date(response.data.date);
  const now = new Date();
  const diff = now - submissionDate;
  const remainingHours = 24 - Math.floor(diff / 1000 / 60 / 60);
  selectedBounty.value.remainingHours = remainingHours;
};

const updateChartData = async (h3Index) => {
  // Start date is 5 days ago.
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 5);
  const endDate = new Date();

  const response = await axios.post(`/api/number-observations-in-hexagon-date-range`, {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    h3Index: h3Index,
  });

  if (!response.data) {
    console.error('Failed to get observation data.');
    return;
  }

  const dataset = createDataset(response.data);
  if (!dataset) {
    console.error('Failed to create dataset.');
    return;
  }

  if (!chartInstance.value) {
    console.error('Chart instance is not initialized.');
    return;
  }
  updateChartDataset(chartInstance.value, dataset);
};

onMounted(async () => {
  await updateBountyInfo();

  if (props.h3Index) {
    console.log(`initial h3index: ${props.h3Index}`);
    await queryBountyInfo(props.h3Index);
  }

  chartInstance.value = createChart(chartCanvas.value);
});

onBeforeUnmount(() => {
  if (chartInstance.value) {
    chartInstance.value.destroy();
  }
});
</script>


<style scoped>
#bounties-container {
  position: relative;
  z-index: 2;
  min-width: 320px;
  width: 100%;
  /* height: 150px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: #ffd972; */
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
}

.chart-wrapper {
  width: 61%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.submit-wrapper {
  width: 39%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

/* .bounty-info-box-wrapper {
  height: 120px;
  top: calc(75% - 60px);
} */

.chart-text {
  font-size: 15px;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  width: 200px;
}

.bounty-info-text {
  text-align: left;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  font-size: 15px;
  width: 120px;
}

.button-wrapper {
  display: flex;
  justify-content: center;
}

.button {
  display: block;
  width: 118px;
  padding: 5px;
  margin: 0px auto;
  margin-bottom: 0px;
  margin-top: 25px;
  border-radius: 5px;
  /* background: #98ffc3; */
  outline: 1px solid #ccc;
  text-align: center;
  text-decoration: none;
  color: black;
  font-weight: 500;
  cursor: pointer;
}

.button-cancel {
  background: #ffffff;
}

.button-confirm {
  background: #98ffc3;
  color: #313131;
}

.chart-container {
  position: relative;
  width: 200px;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
</style>