import * as yup from 'yup';

// Define the avatar schema
const avatarSchema = yup.object({
  name: yup.string().required('Avatar name is required'),
  purchase_date: yup.date().required('Avatar purchase date is required'),
});

// Define the userMetadata schema
export const userMetadataSchema = yup.object({
  sub: yup.string().required('User metadata "sub" is required'),
  name: yup.string().optional('User metadata "name" is optional'),
  given_name: yup.string().optional('User metadata "given_name" is optional'),
  family_name: yup.string().optional('User metadata "family_name" is optional'),
  picture: yup
    .string()
    .url('User metadata "picture" must be a valid URL')
    .optional('User metadata "picture" is optional'),
  email: yup
    .string()
    .email('User metadata "email" must be a valid email')
    .required('User metadata "email" is required'),
  email_verified: yup
    .boolean()
    .required('User metadata "email_verified" is required'),
});

// Define the main user schema
const userSchema = yup.object({
  userMetadata: userMetadataSchema.required('userMetadata is required'),
  avatar: yup.string().required('Avatar is required'),
  created_date: yup
    .date()
    .required('Created date is required')
    .typeError('Created date must be a valid date'),
  last_observation_time: yup
    .date()
    .required('Last observation time is required')
    .typeError('Last observation time must be a valid date'),
  prken_coins: yup
    .number()
    .integer('Prken coins must be an integer')
    .min(0, 'Prken coins cannot be negative')
    .required('Prken coins are required'),
  observations: yup
    .number()
    .integer('Observations must be an integer')
    .min(0, 'Observations cannot be negative')
    .required('Observations are required'),
  avatars: yup
    .array()
    .of(avatarSchema)
    .required('Avatars array is required')
    .min(1, 'Avatars array cannot be empty'),
});

// Function to validate a user entry
export const validateUserEntry = async (userEntry) => {
  try {
    const validData = await userSchema.validate(userEntry, { abortEarly: false });
    console.log('Validation passed:', validData);
    return { valid: true, data: validData };
  } catch (error) {
    console.error('Validation failed:', error.errors);
    return { valid: false, errors: error.errors };
  }
};
