import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const data = {
  labels: createLabels(new Date()),
  datasets: [
    {
      label: 'Cubic interpolation (monotone)',
      data: createDataset([]),
      borderColor: 'rgb(119, 119, 119)', // rgb(255, 99, 132)
      fill: false,
      cubicInterpolationMode: 'monotone',
      tension: 0.4
    },
  ]
};

const config = {
  type: 'line',
  data: data,
  options: {
    responsive: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart - Cubic interpolation mode'
      }
    },
    interaction: {
      intersect: false
    },
    radius: 0,
    hoverRadius: 0,
    scales: {
      x: {
        display: true,
        title: {
          display: false,
        },
        grid: {
          display: false,
        }
      },
      y: {
        display: false,
        title: {
          display: false,
          text: 'Value'
        },
        grid: {
          display: false,
        }
      }
    }
  }
};

/**
 * Generates an array of labels representing the last 5 days from the given date.
 * Each label is a two-letter abbreviation of the weekday in uppercase.
 *
 * @param {Date} date - The reference date from which to generate the labels.
 * @returns {string[]} An array of labels for the last 5 days.
 */
export function createLabels(date) {
  const labels = [];
  // Create labels for the last 5 days
  for (let i = 4; i >= 0; i--) {
    const day = new Date(date);
    day.setDate(date.getDate() - i);
    labels.push(day.toLocaleDateString('en-US', { weekday: 'short' }).substring(0, 2).toUpperCase());
  }
  return labels;
}

export function createChart(canvasRef) {
  const ctx = canvasRef.getContext('2d');
  return new Chart(ctx, config);
}

/**
 * Creates a dataset for the last 5 days based on the provided data.
 * Pads 0 for days where there is no data.
 *
 * @param {Array} data - Array of objects containing date and count properties.
 * @param {string} data[].date - The date string in a format recognized by the Date constructor.
 * @param {number} data[].count - The count value for the given date.
 * @returns {Array<number>} - An array of counts for the last 5 days, with 0 for days with no data.
 */
export function createDataset(data) {
  const dataset = [];
  const dateMap = data.reduce((acc, item) => {
    const date = new Date(item.date).toDateString();
    console.log(date);
    acc[date] = item.count;
    return acc;
  }, {});

  const currentDate = new Date();
  const date = new Date();
  for (let i = 4; i >= 0; i--) {
    // const date = new Date(currentDate);
    date.setDate(currentDate.getDate() - i);
    const dateString = date.toDateString();
    dataset.push(dateMap[dateString] || 0);
  }
  return dataset;
}

export function updateChartDataset(chart, data) {
  chart.data.datasets[0].data = data;
  chart.update();
}