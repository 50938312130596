export const heatmapTakenStyle = (id, sourceName) => {
  return {
    id: id,
    type: 'heatmap',
    source: sourceName,
    filter: ['==', ['get', 'observation_value'], 0], // Only taken slots
    paint: {
      // Heatmap intensity
      'heatmap-intensity': ['interpolate', ['linear'], ['zoom'],
        13, 0,
        19, 50
      ],

      // Heatmap color gradient (red for taken slots)
      'heatmap-color': [
        'interpolate',
        ['linear'],
        ['heatmap-density'],
        0, 'rgba(255, 96, 134, 0)',        // Transparent for no density
        0.4, 'rgba(255, 96, 134, 0.75)',      // Light red for low density
        0.5, 'rgba(255, 96, 134, 0.5)',    // Light red for low density
        1, 'rgba(255, 96, 134, 1.0)',      // Dark red for high density
      ],

      // Heatmap radius
      'heatmap-radius': ['interpolate', ['linear'], ['zoom'],
        10, 2,
        19, 50
      ],

      // Heatmap opacity
      'heatmap-opacity': 0.8,
    }
  };
}

export const heatmapFreeStyle = (id, sourceName) => {
  return {
    id: id,
    type: 'heatmap',
    source: sourceName,
    filter: ['==', ['get', 'observation_value'], 1], // Only free slots
    paint: {
      // Increase the heatmap intensity as the zoom level increases
      'heatmap-intensity': ['interpolate', ['linear'], ['zoom'],
        13, 0, // At zoom 9 (world view), intensity is 0
        19, 50  // At zoom 19 (max), intensity is 5
      ],

      // Heatmap color gradient (green for free slots)
      'heatmap-color': [
        'interpolate',
        ['linear'],
        ['heatmap-density'],
        0, 'rgba(152, 255, 195, 0)',        // Transparent for no density
        0.4, 'rgba(152, 255, 195, 0.75)',      // Light green for low density
        0.5, 'rgba(152, 255, 195, 0.5)',    // Light green for low density
        1, 'rgba(152, 255, 195, 1.0)',      // Dark green for high density
      ],

      // Heatmap radius
      'heatmap-radius': ['interpolate', ['linear'], ['zoom'],
        10, 2, // At zoom 10 (world view), radius is 2
        19, 50 // At zoom 19 (max), radius is 20
      ],

      // Heatmap opacity
      'heatmap-opacity': 0.8,
    },
  };
}

export const avatarLayerStyle = (sourceName) => {
  return {
    'id': 'observations',
    'type': 'symbol',
    'source': sourceName,
    'layout': {
      'icon-image': ['get', 'avatar'],
      'icon-size': 1.0,
    }
  };
}